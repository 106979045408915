import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import RprServiceAreas from '../../../components/RprServiceAreas';

const RealPropertyReports = () => (
  <Layout
    title="Real Property Reports Calgary & Red Deer | Axiom Geomatics"
    description="A Real Property Report (RPR) is a legal document including property dimensions, improvements, permanent features, and more relative to property lines. Axiom Geomatics provides RPR for Calgary and area"
    keywords="real property report, rpr, property survey, house survey, land survey, selling house survey, compliance, letter of compliance, stamp of compliance"
  >
    <main>
      <h1>Real Property Reports Calgary</h1>
      <p>How would you define what a Real Property Report is?</p>

      <p>
        A Real Property Report (RPR) is a legal document prepared by an Alberta Land Surveyor that
        visually illustrates the location of significant improvements in relation to property
        boundaries. It is a requirement of most real estate transactions in Alberta.
      </p>
      <p>Key elements of an RPR include:</p>

      <ul>
        <li>Identification of encroachments on to (or from) adjacent properties</li>
        <li>Easements, restrictive covenants, and right-of-ways</li>
        <li>Compliance with municipal requirements and bylaws</li>
        <li>Boundaries</li>
        <li>Man-made, above-ground permanent features and their relation to property lines</li>
      </ul>

      <p>
        A Real Property Report can help identify potential issues when selling a property. RPRs
        protect property sellers by minimizing the risk of future legal disputes regarding property
        boundaries and protect property buyers with a clear understanding of what they're
        purchasing.
      </p>

      <p>Creating an RPR involves a thorough process:</p>
      <ol>
        <li>A search for any relevant encumbrances registered against the property title</li>
        <li>A review of all plans related to boundary locations</li>
        <li>A field survey of the property, marking all structures on the property</li>
        <li>
          The preparation of a detailed diagram (Plan) based on the results of the survey and title
          search
        </li>
      </ol>

      <p>
        Optionally, the completed RPR can be submitted to the local municipality for a Certificate,
        or Stamp, of Compliance.{' '}
        <Link to="https://axiomgeomatics.ca/services/residential-surveys/compliance-costs">
          Fees for this service vary by municipality.
        </Link>{' '}
        The Certificate of Compliance confirms, based on the RPR provided, that the property
        complies with the local municipal bylaws and regulations.
      </p>

      <h2>RPR Cost vs Update Cost</h2>
      <p>
        The amount of work to prepare an RPR can vary between properties based on factors such as
        lot size, shape, number of buildings, natural features, the property’s age, and availability
        of boundary information.
      </p>

      <p>
        If a previous survey has been done on a property, an updated RPR may be created at a reduced
        cost, depending on various restrictions. If you require an RPR update please contact Axiom
        Geomatics to discuss potential savings.
      </p>

      <h2>I'm selling a Condo, do I need a Real Property Report?</h2>
      <p>
        This can be a complex issue, as there are different types of condominiums. Typically, a
        "conventional" or "building" condominium, often referred to as an apartment-style condo,
        does not need a Real Property Report. In these types of condos, usually, the homeowner owns
        and controls the interior space within their unit and/or parking space. However, a "bare
        land" condominium typically requires a Real Property Report. For more information specific
        to your property, please contact us with the details.
      </p>

      <h2>I have an old Real Property Report, do I need a new one?</h2>
      <p>
        Generally, old surveys will need to be updated. Typically, a Real Property Report is
        considered valid, as long as it accurately represents the current property. Two common
        reasons a Real Property Report may no longer be valid are:
      </p>
      <ol>
        <li>
          Changes to the property, such as altering the size or location of structures on the
          property. Typical changes include additions, alterations, or removal of fences, sheds,
          decks, and garages.
        </li>
        <li>
          Changes to the municipal RPR content requirements, which can occur frequently.
          Unfortunately, this can make an old RPR invalid, despite there being no changes to the
          property.
        </li>
      </ol>

      <h2>What is Municipal Compliance?</h2>
      <p>
        A letter, or stamp, of compliance, is a certification provided by most municipalities for a
        fee. This letter or stamp indicates that the property complies with municipal by-laws
        relevant to the Real Property Report. If you encounter any difficulties while obtaining a
        stamp of compliance, please contact Axiom Geomatics for assistance.
      </p>

      <h2>Can I use my Real Property Report for fence lines?</h2>
      <p>
        We do not recommend using a Real Property Report to establish a property line for
        constructing fences. It is difficult for homeowners to achieve the same accuracy as
        surveyors as they use legal pins to mark the property line. If you require a boundary for
        fence line construction or any other purpose, please contact us for a Property or Fence Line
        Stakeout. Both the RPR and the{' '}
        <Link to="https://axiomgeomatics.ca/services/residential-surveys/property-line-surveys">
          Fence Line Stakeout
        </Link>{' '}
        can be completed simultaneously, offering savings in both time and cost.
      </p>

      <p>
        <Link to="/order-a-real-property-report" className="btn">
          Order a Real Property Report
        </Link>{' '}
        or{' '}
        <Link to="/services/residential-surveys/compliance-costs" className="btn">
          View Certificate of Compliance Costs
        </Link>
      </p>
    </main>

    <aside>
      <RprServiceAreas />
    </aside>
  </Layout>
);

export default RealPropertyReports;
